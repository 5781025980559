import { Product } from "@/types";
import { urlFor } from "@/utils/sanityUtil";
import { Button } from "antd";
import { motion } from "framer-motion"
import Image from 'next/image';
import { useRouter } from "next/router";
import _ from 'lodash';
import { useMemo } from "react";
import { sendToWhatsAPP } from "@/utils/whatsappUtil";

type ProductItemProps = {
    product: Product
}

const ProductItem = ({product}: ProductItemProps) => {
    const router = useRouter();

    const price = useMemo(() => {
        const variantCount = product.variants?.length;
        if (variantCount === 1) {
            const productPrice = product.variants[0].price;
            return productPrice ? `${productPrice} LKR` : 'Price Not Set';
        } else if (variantCount > 1) {
            const minPrice = _.minBy(product.variants, 'price')?.price;
            const maxPrice = _.maxBy(product.variants, 'price')?.price;

            return `${minPrice} - ${maxPrice} LKR`;
        } else {
            return 'Price Not Set'
        }
    }, [product.variants]);

    const onBuy = () => {
        if (product.variants?.length > 1) {
            router.push(`/shop/${product.productCategory.slug.current}/${product.slug.current}`);
        } else {
            const productUrl = `https://chethis.lk/shop/${product?.productCategory?.slug?.current}/${product?.slug?.current}`;
            const price = product.variants?.[0].price;
            sendToWhatsAPP(router, product.title, 1, product.variants[0].sku, price, productUrl);
        }
    }
    return (
        <motion.div
            key={product._id}
            initial={{ scale: 0.9 }} 
            whileInView={{ scale: 1 }}
            transition={{ type: 'spring', duration: 1.5}}
            whileHover={{ scale: 1.02 }}
            className='ch-product-item-card'
        >
            <span 
                className='ch-product-item-card-container' 
                onClick={() => router.push(`/shop/${product.productCategory.slug.current}/${product.slug.current}`)}
            >
                <Image 
                    className='ch-product-item-card-img' 
                    src={urlFor(product.mainImage)} 
                    alt={product.title} 
                    fill
                    sizes="100%"
                    priority
                />
                <div className='ch-product-item-card-category'>{product.productCategory.title}</div>
                <div className='ch-product-item-card-title'>{product.title}</div>
                <div className="ch-product-item-card-details">
                    <div className='ch-product-item-card-price'>{price}</div>
                    <div className='ch-product-item-card-availability'>{product.variants[0].stock > 0 ? 'In Stock' : 'Out of Stock'}</div>
                </div>
            </span>
            <div className='ch-product-item-card-actions'>
                <Button onClick={onBuy} type="primary" className="ch-btn" >
                    {product.variants?.length > 1 ? 'View Details': 'Quick Buy'}
                </Button>
            </div>
        </motion.div>
    );
};

export default ProductItem;