import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Image from 'next/image';

// Sample data for products
const products = [
  { id: 1, name: 'Product 1', image: '/assets/carousel/image1.webp', text: 'Dream Fashion To Reality' },
  { id: 2, name: 'Product 2', image: '/assets/carousel/image2.webp', text: 'Eco Friendly Products' },
  { id: 3, name: 'Product 3', image: '/assets/carousel/image3.webp', text: 'Dream Fashion To Reality' },
  { id: 4, name: 'Product 4', image: '/assets/carousel/image4.webp', text: 'Eco Friendly Products' },
  { id: 5, name: 'Product 5', image: '/assets/carousel/image5.webp', text: 'Dream Fashion To Reality' },
  // Add more products as needed
];

const HomeCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === products.length - 1 ? 0 : prevIndex + 1));
  };

  // Set auto-transition interval (in milliseconds)
  const transitionInterval = 5000;

  // Auto-transition between slides
  React.useEffect(() => {
    const interval = setInterval(nextSlide, transitionInterval);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='ch-home-carousel'>
      <AnimatePresence mode='sync' initial={false}>
        <motion.div
            key={currentIndex}
            initial={{ opacity: 0, x: '100%' }}
            animate={{ opacity: 1, x: '0%' }}
            exit={{ opacity: 0, x: '-100%' }}
            transition={{ duration: 2 }}
            className='ch-home-carousel-container'
        > 
            <div className='ch-home-carousel-slide'>
              <motion.div
                initial={{ opacity: 0.5 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0.5 }}
                transition={{ duration: 2 }}
                style={{ zIndex: 90 }}
                className='ch-home-carousel-slide-img'
              >
                <Image 
                  priority
                  src={products[currentIndex].image} 
                  alt={products[currentIndex].name} 
                  fill
                  sizes="100%"
                  placeholder="blur"
                  blurDataURL={products[currentIndex].image}
                />
              </motion.div>
              <div className='ch-home-carousel-slide-holder'>
                <motion.div
                  initial={{ opacity: 0, y: 140 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 140 }}
                  transition={{ duration: 2 }}
                  className='ch-home-carousel-slide-desc'
                >
                  <p>{products[currentIndex].text}</p>
                </motion.div>
              </div>
            </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default HomeCarousel;