import '@/styles/globals.scss';
import { Layout } from '@/components';
import type { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';

import SEO from '../../next-seo.config';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo {...SEO} />
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
}
