module.exports = {
  title: 'Chethi\'s - Eco friendly fashion solution',
  description: 'Chethi\'s is an online eco-friendly fashion store where customers can create their own unique style.',
  canonical: 'https://chethis.lk/',
  additionalLinkTags: [
    {
      rel: 'icon',
      href: 'https://chethis.lk/favicon.ico',
    },
    {
      rel: 'apple-touch-icon',
      href: 'https://chethis.lk/apple-touch-icon.png',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest'
    },
  ],
  openGraph: {
    type: 'website',
    locale: 'en',
    url: 'https://chethis.lk/',
    siteName: 'Chethi\'s Fashion',
  },
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
  },
};