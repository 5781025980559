import { Layout } from "antd";
import Header from "../Header";
import Footer from "../Footer";
import Head from "next/head";
import { Poppins, Open_Sans } from 'next/font/google';

const {Content} = Layout

type MainLayoutProps = {
    children: React.ReactNode;
}

export const popinsInit = Open_Sans({
    subsets: ['latin'],
    display: 'swap',
    variable: '--font-open-sans',
    weight: ['400', '500', '700']
});

export const openSansInit = Poppins({
    subsets: ['latin'],
    display: 'swap',
    variable: '--font-popins',
    weight: ['400', '500', '700']
});

const MainLayout = ({ children }: MainLayoutProps) => {
    return (
        <Layout>
            <Head>
                <link rel='shortcut icon' href='/favicon.ico' />
                <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <Header/>
            <Content className={`${popinsInit.variable} ${openSansInit.variable}`}>{children}</Content>
            <Footer />
        </Layout>
    );
}

export default MainLayout;