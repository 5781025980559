import * as React from "react";
import { Cycle, motion } from "framer-motion";
import { NavItem } from "@/types";
import { useRouter } from "next/router";

type MenuItemProps = {
  menuItem: NavItem;
  toggle: Cycle;
  isOpen: boolean;
}

const variants = {
  open: {
    y: 0,
    opacity: 1,
    zIndex: 191,
    display: 'flex',
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    zIndex: -1,
    transition: {
      y: { stiffness: 1000 },
    },
    pointerEvent: 'none',
    transitionEnd: {
      display: 'none'
    }
  }
};

export const MenuItem = ({ menuItem, toggle, isOpen }: MenuItemProps) => {

  const router = useRouter();

  const onMenuItemClick = () => {
    if (isOpen) {
      router.push(menuItem.path); 
      toggle();
    }
  }

  const menuItemProps = {
    whileTap: {scale: 0.95}
  };

  return (
    <motion.li
      variants={variants}
      onClick={onMenuItemClick}
      {...(isOpen ? menuItemProps : {})}
    >
      <div className="icon-placeholder">
        {menuItem.icon}
      </div>
      <div className="text-placeholder">
        {menuItem.name}
      </div>
    </motion.li>
  );
};
