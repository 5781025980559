import {Image} from 'antd';
import { motion } from 'framer-motion';
import { MobileMenuBar } from '..';
import { ContactsOutlined, HeartOutlined, HomeOutlined, ShoppingOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';

type HeaderContentProps = {
    className?: string;
}

const navConfigurations = [
    {id: 'home', name: 'Home', path: '/', icon: <HomeOutlined />},
    {id: 'shop', name: 'Shop', path: '/shop', icon: <ShoppingOutlined />},
    {id: 'about', name: 'About', path: '/about-us', icon: <HeartOutlined />},
    {id: 'contact_us', name: 'Contact', path: '/contact-us', icon: <ContactsOutlined />},
]

const HeaderContent = ({className}: HeaderContentProps = {className: ''}) => {
    const router = useRouter();
    return (
        <div className={`ch-header ${className}`}>
            <MobileMenuBar navConfigurations={navConfigurations} />
            <motion.div
                className='ch-header-logo'
                initial={{ opacity: 0, y: -10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.75 }}
            >
                <Image preview={false} src='/assets/images/chethisLogo.svg' alt='Chethis'/>
            </motion.div>
            <div className='ch-header-nav desktop'>
                {navConfigurations.map(navigation => (
                    <motion.div
                        key={navigation.id}
                        initial={{ opacity: 0, y: -10 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.75 }}
                        onClick={() => router.push(navigation.path)}
                    >
                        {navigation.name}
                    </motion.div>
                ))}
            </div>
        </div>
    )
}

export default HeaderContent;