import { FacebookOutlined, InstagramOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Image } from "antd";
import Link from "next/link";

const Footer = () => {
    return (
        <div className="ch-footer">
            <div className="ch-footer-section">
                <div className="ch-footer-contact">
                    <Image preview={false} src="/assets/images/chethisLogo.svg" alt="Chethi's" width={200} height={70} />
                    <div className="ch-footer-contact-container">
                        <div className="ch-footer-contact-title">Contact</div>
                        <div className="ch-footer-contact-line"><strong>Address:</strong> Karawwa, Pitigala, Galle, Sri Lanka.</div>
                        <div className="ch-footer-contact-line"><strong>Phone:</strong> {'(+94) 77 641 1718'}</div>
                        <div className="ch-footer-contact-line"><strong>Hours:</strong> 09:00 - 17:00, Mon - Sat</div>
                    </div>
                </div>
                <div className="ch-footer-follow-us">
                    <div className="ch-footer-follow-us-title">Follow Us</div>
                    <div className="ch-footer-follow-us-links">
                        <Link target="_blank" href="https://www.facebook.com/share/ZVT5zPEhndhsPvsu/?mibextid=qi2Omg"><FacebookOutlined /></Link>
                        <Link target="_blank" href="https://www.instagram.com/chethi.s_?utm_source=qr&igsh=Y3dpc2kxMG5zMzM="><InstagramOutlined /></Link>
                        <Link target="_blank" href="https://wa.me/94776411718"><WhatsAppOutlined /></Link>
                    </div>
                </div>
            </div>
            <div className="ch-footer-ownership">
                <div>© 2024, Chethi&apos;s</div>
                <div>Designed by Lahiru. All rights reserved</div>
            </div>
        </div>
    );
}

export default Footer;