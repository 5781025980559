import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import HeaderContent from './HeaderContent';

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY >= 100);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <motion.div
                initial={{ y: -101 }}
                animate={{ y: isScrolled ? 0 : -101 }}
                transition={{ type: 'spring', stiffness: 200, damping: 30 }}
                style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 999,
                opacity: 1
                }}
            >
                {isScrolled && <HeaderContent className="ch-fixed-header"/>}
            </motion.div>
            <div style={{ visibility: isScrolled ? 'hidden' : 'visible' }}>
                <HeaderContent />
            </div>
        </div>
    )
}

export default Header;