import * as React from "react";
import { Cycle, motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import { NavItem } from "@/types";

type NavigationProps = {
  navConfigurations: NavItem[];
  toggle: Cycle;
  isOpen: boolean;
}

const variants = {
  open: {
    display: 'block',
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1, when: "afterChildren" },
    transitionEnd: { display: 'none' }
  }
};

export const Navigation = ({navConfigurations, toggle, isOpen}: NavigationProps) => (
  <motion.ul variants={variants}>
    {navConfigurations.map(navItem => (
      <MenuItem menuItem={navItem} key={navItem.id} toggle={toggle} isOpen={isOpen} />
    ))}
  </motion.ul>
);
