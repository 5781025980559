import { createClient } from "@sanity/client"
import imageUrlBuilder from '@sanity/image-url';
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

export const client = createClient({
   projectId: '8i6djm3q', 
   dataset: "production", 
   apiVersion: "2024-03-11",
   // Set to `true` for production environments
   useCdn: false, 
});

export const urlFor = (source: SanityImageSource) => {
  return imageUrlBuilder(client).image(source).url();
}

export const sanityFetch = (query: string) => {
  return client.fetch(query);
}