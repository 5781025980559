import * as React from "react";
import { motion, useCycle } from "framer-motion";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";
import { NavItem } from "@/types";

type MobileMenuBarProps = {
  navConfigurations: NavItem[]
}

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
      delay: 0.3
    }
  }
};

const MobileMenuBar = ({ navConfigurations }: MobileMenuBarProps) => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
    >
      <motion.div className="background" variants={sidebar} />
      <MenuToggle toggle={() => toggleOpen()}/>
      <Navigation navConfigurations={navConfigurations} toggle={toggleOpen} isOpen={isOpen}/>
    </motion.nav>
  );
};

export default MobileMenuBar;